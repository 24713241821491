import routes from "actions/routes";
import { generateReadActionCreator, generateCreateActionCreator, generateReadOneActionCreator } from "actions/crudActionCreators";
import ACTION_NAMES from "actions/ACTION_NAMES";

function markContact(id) {
    return {
        type: 'MARK_CONTACT',
        id
    }
}

function markAllContacts() {
    return {
        type: 'MARK_ALL_CONTACTS'
    }
}

function markAllPages() {
    return {
        type: 'MARK_CONTACTS_IN_EVERY_PAGE'
    }
}

const readContacts = generateReadActionCreator(routes.API_CONTACTS, ACTION_NAMES.CONTACTS);
const filterContacts = generateReadActionCreator(routes.API_CONTACT_AUTOCOMPLETE_FILTER,  ACTION_NAMES.CONTACT_AUTOCOMPLETE_FILTER);
const readRecentContacts = generateReadActionCreator(routes.API_CONTACTS, ACTION_NAMES.RECENT_CONTACTS);
const contactSearch = generateReadActionCreator(routes.API_CONTACT_SEARCH, ACTION_NAMES.CONTACT_SEARCH);
const viewLookup = generateReadOneActionCreator(routes.API_CONTACT_VIEW_LOOKUP, ACTION_NAMES.CONTACT_VIEW_LOOKUP);

export default {
    readContacts,
    readRecentContacts,
    createContact: generateCreateActionCreator(routes.API_CONTACTS, ACTION_NAMES.CONTACTS),
    markContact,
    markAllContacts,
    filterContacts,
    markAllPages,
    contactBulkAction: generateCreateActionCreator(routes.API_CONTACTS_BULK, ACTION_NAMES.CONTACTS_BULK),
    contactSearch,
    viewLookup
}
