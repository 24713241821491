import React, { useState } from 'react';

import './index.scss';
import { Header, Icon } from 'semantic-ui-react';
import { OfficeViewContainer, OfficeViewHeader, OfficeViewLeftPanel, OfficeViewDialPad, generateResolver, yup, OfficeViewContent, OfficeViewPhoneMiddle } from 'dyl-components';
import { useForm } from 'react-hook-form';

const OfficeView = ({ onClose }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [search, setSearch] = useState("");
    const [isSearchResultsVisible, setIsSearchResultsVisible] = useState(false);
    const [page, setPage] = useState(1);

    const pages = [
            { id: 0, icon: "fa-solid fa-phone", component: OfficeViewPhoneMiddle },
            { id: 1, icon: "fa-solid fa-user-clock", component: () => <></> },
            { id: 2, icon: "fa-solid fa-comments", component: () => <></> },
            { id: 3, icon: "fa-solid fa-gear", component: () => <></> }
    ];

    const { formState: { isValid }, control, watch, setValue, trigger } = useForm({
        mode: 'onChange',
        defaultValues: {
            dial: "",
        },
        resolver: generateResolver({
            dial: yup.string().minlength(3).required("")
        })
    });

    const dialedNumber = watch("dial");

    const handleNumberClick = (number) => {
        setValue('dial', number);
        trigger('dial');
    }

    const cancelFunction = async () => {
        setSearch("");
        setPage(1);
        setIsSearchResultsVisible(false);
    }

    const handleClose = () => {
        cancelFunction();
        onClose();
    }

    return (
        <div style={{padding: 30}}>
            <div style={{display: 'flex', marginBottom: 10}}>
                <Header style={{flex: 1}} as={"h1"}>Office View</Header>
                <Icon className='fa-solid fa-xmark closeIcon' size="big" onClick={handleClose} />
            </div>
            <div>
                <OfficeViewContainer
                    header={ <OfficeViewHeader /> }
                    leftPanel={ <OfficeViewLeftPanel pages={pages} selectedTab={selectedTab} setSelectedTab={setSelectedTab} /> }
                    rightPanel={ <OfficeViewDialPad control={control} dialedNumber={dialedNumber} dialButtonDisabled={!isValid} onDial={() => {}} /> }
                >
                    <OfficeViewContent 
                        pages={pages} 
                        selectedTab={selectedTab} 
                        onNumberClick={handleNumberClick}
                        search={search}
                        setSearch={setSearch}
                        isSearchResultsVisible={isSearchResultsVisible}
                        setIsSearchResultsVisible={setIsSearchResultsVisible}
                        page={page}
                        setPage={setPage}
                        cancelFunction={cancelFunction}
                    />
                </OfficeViewContainer>
            </div>
        </div>
    )
}

export default OfficeView;
