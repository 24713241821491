import paymentActions from "actions/payment";
import { ButtonLink, DateTimeUtils } from "dyl-components";
import { parse } from "query-string";
import { useEffect, useRef } from "react";
import { Controller, useController, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Form, Grid, Radio, Segment } from "semantic-ui-react";
import { StringUtils } from "utils";

const hostedProfileSettings = [
    {
        settingName: "hostedProfileSaveButtonText",
        settingValue: `Add`,
    },
    {
        settingName: "hostedProfilePageBorderVisible",
        settingValue: `false`,
    },
    {
        settingName: "hostedProfileHeadingBgColor",
        settingValue: `#2B78FF`,
    },
    {
        settingName: "hostedProfileValidationMode",
        settingValue: "testMode",
    },
    {
        settingName: "hostedProfileManageOptions",
        settingValue: "showPayment",
    },
    {
        settingName: "hostedProfileBillingAddressRequired",
        settingValue: "true"
    },
    {
        settingName:
            "hostedProfileIFrameCommunicatorUrl",
        settingValue: `${`http${
                    window.location.host === "localhost:3001" ? "" : "s"
                }://${window.location.host}`}/index.html`,
    },
];

const NewPaymentMethod = ({ form_token, onCancel, onSave }) => {
    const payElement = useRef();
    const iframeRef = useRef();
    const { control } = useFormContext();
    const { field: paymentMethodField } = useController({
        name: "payment_method",
        control,
    });

    useEffect(() => {
        payElement.current?.submit({ target: "add_payment" });

        window.AuthorizeNetIFrame = {
            onReceiveCommunication: (queryStr) => {
                const params = parse(queryStr);

                switch (params["action"]) {
                    case "successfulSave":
                        onSave();
                        break;
                    case "cancel":
                        onCancel();
                        break;
                    case "resizeWindow": {
                        const iframe = iframeRef.current;
                        if (iframe) {
                            iframe.style.width = `${params["width"]}px`;
                            iframe.style.height = `${params["height"]}px`;
                        }
                        break;
                    }
                    case "transactResponse":
                        if (iframeRef.current) {
                            iframeRef.current.style.display = "none";
                        }
                        break;
                    default:
                        console.log("Unknown action:", params["action"]);
                }
            },
        };

        return () => {
            delete window.AuthorizeNetIFrame;
        };
    }, [paymentMethodField, onCancel, onSave]);

    return (
        <>
            <form
                ref={payElement}
                target="add_payment"
                method="post"
                action="https://test.authorize.net/customer/addPayment"
            >
                <input type="hidden" name="token" value={form_token} />
            </form>
            <div
                id="iframe_holder"
                className="Checkout__payment center-block"
            >
                <iframe
                    ref={iframeRef}
                    title="Add Payment"
                    id="add_payment"
                    className="embed-responsive-item panel"
                    name="add_payment"
                    width="100%"
                    style={{ overflow: "hidden" }}
                ></iframe>
            </div>
        </>
    );
};

const ExistingPayments = ({ account_id }) => {
    const { paymentMethods } = useSelector((state) => ({
        paymentMethods:
            state.payment.profile?.paymentProfiles?.map(
                ({ customerPaymentProfileId, payment: paymentInfo }) => {
                    const details = (() => {
                        if (paymentInfo.creditCard) {
                            const {
                                cardType: type,
                                cardNumber: number,
                                expirationDate,
                            } = paymentInfo.creditCard;
                            return {
                                type,
                                number,
                                otherInfo: `Exp. ${DateTimeUtils.changeFormat(expirationDate, "YYYY-DD", "DD/YYYY")}`,
                            };
                        }
                        const {
                            accountType: type,
                            accountNumber: number,
                            nameOnAccount: otherInfo,
                        } = paymentInfo.bankAccount;
                        return {
                            type: (() => {
                                if (type !== "businessChecking") {
                                    return StringUtils.capitalize(type);
                                }
                                return "Business Checking";
                            })(),
                            number,
                            otherInfo,
                        };
                    })();
                    const number = details.number.replace("XXXX", "****");
                    const content = (
                        <Grid columns={"equal"}>
                            <Grid.Column>{details.type}</Grid.Column>
                            <Grid.Column>{number}</Grid.Column>
                            <Grid.Column>{details.otherInfo}</Grid.Column>
                        </Grid>
                    );
                    return {
                        key: customerPaymentProfileId,
                        value: customerPaymentProfileId,
                        text: content,
                        content: content,
                    };
                }
            ) || [],
    }));

    const dispatch = useDispatch();

    const { control } = useFormContext();

    const { field: paymentMethodField } = useController({
        name: "payment_method",
        control,
    });

    const onSelectPaymentMethod = (_, { value }) => {
        if (value !== "New Payment Method") {
            paymentMethodField.onChange({
                target: {
                    name: paymentMethodField.name,
                    value,
                },
            });
        } else {
            paymentMethodField.onChange({ target: { name: paymentMethodField.name, value: null } });
            dispatch(
                paymentActions.generateAcceptCustomerFormToken(
                    {
                        hostedProfileSettings: hostedProfileSettings,
                    },
                    null,
                    account_id
                )
            );
        }
    };

    return (
        <Grid>
            <Grid.Column width={8}>
                <Form.Dropdown
                    className="Checkout__payment-method"
                    label="Primary Payment Method"
                    selection
                    selectOnBlur={false}
                    value={paymentMethodField.value}
                    onChange={onSelectPaymentMethod}
                    options={[
                        ...paymentMethods,
                        {
                            key: "New Payment Method",
                            value: "New Payment Method",
                            text: "New Payment Method",
                            content: (
                                <ButtonLink noPadding>
                                    + New Payment Method
                                </ButtonLink>
                            ),
                        },
                    ]}
                    fluid
                />
            </Grid.Column>
        </Grid>
    );
};

const PaymentMethod = ({
    selectedPaymentMethod,
    onChangeSelectedPaymentMethod,
    account_id,
}) => {
    const isActive = (method) => {
        return selectedPaymentMethod === method;
    };

    const getClassName = (method) => {
        return `PaymentMethod${
            isActive(method) ? ` PaymentMethod--active` : ""
        }`;
    };

    const { control } = useFormContext();

    const { isReadingPaymentInfo, form_token } = useSelector((state) => ({
        isReadingPaymentInfo:
            state.payment.isReadingCustomerFormToken ||
            state.payment.isReadingCustomerProfile,
        form_token: state.payment.customerFormToken,
    }));

    const dispatch = useDispatch();

    const { field: paymentMethodField } = useController({
        name: "payment_method",
        control,
    });

    const onChooseBankPayment = () => {
        onChangeSelectedPaymentMethod("bank");
        paymentMethodField.onChange({ target: { name: paymentMethodField.name, value: null } });
        dispatch(paymentActions.resetFormToken());
        dispatch(paymentActions.getProfiles(account_id)).then((response) => {
            if (!response.profile.paymentProfiles) {
                dispatch(
                    paymentActions.generateAcceptCustomerFormToken(
                        {
                            hostedProfileSettings: hostedProfileSettings,
                        },
                        null,
                        account_id
                    )
                );
            }
        });
    };
    return (
        <Segment.Group>
            <Segment className={getClassName("bank")} compact>
                <Radio
                    label="Credit Card/E-Check"
                    checked={isActive("bank")}
                    onChange={onChooseBankPayment}
                />
            </Segment>
            {isReadingPaymentInfo && (
                <Segment
                    style={{ height: "5vh" }}
                    loading={isReadingPaymentInfo}
                />
            )}
            {isActive("bank") && !isReadingPaymentInfo && (
                <Segment>
                    {form_token ? (
                        <NewPaymentMethod
                            form_token={form_token}
                            account_id={account_id}
                            onCancel={() => {onChangeSelectedPaymentMethod(null)}}
                            onSave={() => {
                                dispatch(paymentActions.resetFormToken());
                                dispatch(paymentActions.getProfiles(account_id));
                            }}
                        />
                    ) : (
                        <ExistingPayments account_id={account_id} />
                    )}
                </Segment>
            )}
            <Segment className={getClassName("custom-ach")} compact>
                <Radio
                    label="Custom (ACH)"
                    checked={isActive("custom-ach")}
                    onChange={() => {
                        onChangeSelectedPaymentMethod("custom-ach");
                        paymentMethodField.onChange({ target: { name: paymentMethodField.name, value: "custom-ach" } })
                    }}
                />
            </Segment>
            {isActive("custom-ach") && (
                <Segment style={{ minHeight: "11em" }}>
                    <Form noValidate>
                        <Controller
                            name="custom_ach"
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <Form.TextArea
                                    placeholder="Insert account payable information (bank, account #)"
                                    value={value}
                                    onChange={(_, { value }) => {
                                        onChange({ target: { name, value } });
                                    }}
                                    error={error?.message}
                                />
                            )}
                        />
                    </Form>
                </Segment>
            )}
        </Segment.Group>
    );
};

export default PaymentMethod;
