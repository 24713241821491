import { Modal, Notification, STATUS_TYPES, Step } from "dyl-components";
import { useContext, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, Form, Grid, Icon, Segment } from "semantic-ui-react";
import OrderSummary from "./OrderSummary";
import { useDispatch, useSelector } from "react-redux";
import account from "actions/account";
import { CURRENT_STEPS, QuoteBuilderContext } from "shared/context/QuoteBuilderProvider";

import orderActions from "actions/order";
import { formatOrder } from "shared/schemas/cart/cartSchema";
import FullScreenModalPopUp from "shared/FullScreenModalPopUp";

const STEPS = [
    {
        icon: <Icon className="fas fa-box-dollar" size="large" />,
        title: "Order",
        active: true,
    },
    {
        icon: <Icon className="fas fa-file-invoice-dollar" size="large" />,
        title: "Checkout",
    },
];

const Order = ({ account_id }) => {
    const {
        formState: { isValid, isDirty },
        getFieldState,
        handleSubmit,
        control
    } = useFormContext();

    const order = useSelector((state) => state.order.order);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(account.readContactsForPinning(account_id));
    }, [dispatch, account_id]);

    const { onCheckout, onOpenAddProducts, onViewOrder, quoteBuilderConfig, onEditOrder, onRefreshAccountInformation } =
        useContext(QuoteBuilderContext);

    const { id: order_id, currentStep } = quoteBuilderConfig;

    const updateOrder = async (data) => {
        await dispatch(orderActions.updateAddresses(account_id, {
            shipping: {
                location: data.shipping_address,
                ship_to: data.shipping_address.ship_to
            }
        }, null, `${order_id}/location`));
        return dispatch(
            orderActions.update(
                account_id,
                {
                    order_items: formatOrder(data.cart).map(item => ({
                        ...item,
                        addons: (item?.addons || []).map(addon => ({
                            id: addon,
                            cart_item_id: item.product.cart_item_id
                        }))
                    })),
                    order_name: data.order_name,
                    order_status: data.order_status
                },
                null,
                order_id
            )
        );
    }

    const onUpdateOrder = async (data) => {
        try {
            await updateOrder(data);
            onEditOrder(order_id, account_id);
            Notification.alert("Successfully updated order!", STATUS_TYPES.SUCCESS)
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to update order", STATUS_TYPES.ERROR);
        }
    }

    const onProceedToCheckout = async (data) => {
        try {
            await updateOrder(data);
            onCheckout({ checkout: data });
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to proceed to checkout", STATUS_TYPES.ERROR);
        }
    };

    const isAllowedToEdit =
        order?.order_status === "open" ||
        order?.order_status === "draft" ||
        !Boolean(order?.order_status);

    const onCreateOrder = async (data) => {
        try {
            const order_id = await dispatch(
                orderActions.create(
                    {
                        items: formatOrder(data.cart),
                        name: `Duplicate of ${data.order_name || "Order"}`
                    },
                    {
                        account_id,
                    }
                )
            );
            Notification.alert(
                "Successfully duplicated order",
                STATUS_TYPES.SUCCESS
            );
            onViewOrder(order_id, account_id);
            onRefreshAccountInformation();
        } catch (e) {
            console.log(e);
            Notification.alert("Failed to duplicate order", STATUS_TYPES.ERROR);
        }
    };

    const isSaving = useSelector(
        (state) => state.order.isCreating || state.order.orderBeingUpdated
    );

    const { fields: cart, replace } = useFieldArray({
        control,
        name: "cart",
        keyName: "key",
    });

    const shouldConfirmCartChanges = getFieldState('cart')?.isDirty && currentStep === CURRENT_STEPS.EDIT_ORDER;

    const [actionBeingConfirmed, setActionBeingConfirmed] = useState(null);

    return (
        <>
            <Modal.Content scrolling>
                <Form loading={isSaving} size="small" noValidate>
                    <Segment size="tiny" basic>
                        <Grid>
                            {isAllowedToEdit && (
                                <Grid.Row>
                                    <Grid.Column>
                                        <Step.Group horizontal>
                                            {STEPS.map(({ icon, ...step }) => (
                                                <Step {...step} key={step.name}>
                                                    {icon}
                                                </Step>
                                            ))}
                                        </Step.Group>
                                    </Grid.Column>
                                </Grid.Row>
                            )}
                            <Grid.Row>
                                <Grid.Column>
                                    <OrderSummary
                                        quote={{
                                            quote_summary: order.order_items,
                                        }}
                                        account_id={account_id}
                                        disabled={!isAllowedToEdit}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                {isAllowedToEdit ? (
                    <>
                        <Button
                            basic
                            onClick={() => {
                                replace(cart.filter((item) => !Boolean(item.removed)));
                                onOpenAddProducts({
                                    order_id,
                                    isUpsell: true,
                                });
                            }}
                            type="button"
                            color="primary"
                            disabled={isSaving}
                        >
                            Back to Add Products
                        </Button>
                        <Button
                            disabled={!isValid || !isDirty || isSaving}
                            type="button"
                            color="primary"
                            basic
                            onClick={shouldConfirmCartChanges ? () => {setActionBeingConfirmed('update')} : handleSubmit(onUpdateOrder)}
                            loading={isSaving}
                        >
                            Save
                        </Button>
                        <Button
                            disabled={!isValid || isSaving}
                            type="button"
                            color="primary"
                            onClick={shouldConfirmCartChanges ? () => {setActionBeingConfirmed('checkout')} : handleSubmit(onProceedToCheckout)}
                            loading={isSaving}
                        >
                            Next
                        </Button>
                    </>
                ) : (
                    <Button
                        disabled={!isValid || isSaving}
                        type="button"
                        color="primary"
                        onClick={handleSubmit(onCreateOrder)}
                        loading={isSaving}
                    >
                        Create New Order
                    </Button>
                )}
            </Modal.Actions>
            <FullScreenModalPopUp
                header={'Are you sure?'} 
                subheader={'Product changes will be applied.'} 
                isOpen={actionBeingConfirmed}
                onConfirm={() => {
                    handleSubmit(actionBeingConfirmed === 'update' ? onUpdateOrder : onProceedToCheckout)();
                    setActionBeingConfirmed(null)
                }}
                onFormClose={() => setActionBeingConfirmed(null)}
                closeIcon={false}
            />
        </>
    );
};

export default Order;
