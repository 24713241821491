import { Table } from "dyl-components";
import SubscriptionsTableHeader from "./SubscriptionsTableHeader";
import { useSelector } from "react-redux";
import Subscription from "./Subscription";

const SubscriptionsTable = ({ selectAllCheckbox, RowCheckboxComponent }) => {
    const { subscriptions } = useSelector((state) => ({
        subscriptions: state.subscription.products,
    }));

    return (
        <Table>
            <Table.Header primary>
                <SubscriptionsTableHeader selectAllCheckbox={selectAllCheckbox} />
            </Table.Header>
            <Table.Body>
                {subscriptions.map((subscription) => (
                    <Subscription
                        RowCheckboxComponent={RowCheckboxComponent}
                        key={subscription.subscription_id}
                        id={subscription.subscription_id}
                        productInfo={<div>
                            <div>
                                <b>{subscription.product_name}</b>
                            </div>
                            {subscription?.variant_name && (
                                <div>
                                    <small>
                                        (
                                        {subscription?.variant_name}
                                        /
                                        {subscription?.variant_value}
                                        )
                                    </small>
                                </div>
                            )}
                        </div>}
                        user={subscription.user}
                        price={subscription.subtotal} />
                ))}
            </Table.Body>
        </Table>
    );
};

export default SubscriptionsTable;
