import { Grid, Segment } from "semantic-ui-react";
import SubscriptionsTable from "./SubscriptionsTable";
import SubscriptionTotals from "./SubscriptionTotals";
import { Button } from "dyl-components";
import { useEffect, useState } from "react";
import subscriptionActions from "actions/subscription";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EditSubscriptions from "./EditSubscriptions";
import BulkActionsProvider from "shared/context/BulkActionsProvider";

const SubscriptionProducts = () => {
    const dispatch = useDispatch();
    const { account_id } = useParams();

    useEffect(() => {
        dispatch(subscriptionActions.getAllProducts(account_id))
    }, [dispatch, account_id]);

    const isReadingProducts = useSelector(state => state.subscription.isReadingProducts);
    const [isEditing, setIsEditing] = useState(false);

    return (
        <Segment basic loading={isReadingProducts}>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Button onClick={() => {setIsEditing(true)}} floated='right' color="primary">Edit Products</Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <SubscriptionsTable />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Segment>
                            <SubscriptionTotals
                                subtotal={0}
                                total={0}
                                totalDiscount={0}
                                totalFee={0}
                                totalTax={0}
                            />
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <BulkActionsProvider>
                <EditSubscriptions 
                    onClose={() => {
                        setIsEditing(false)
                    }}
                    open={isEditing}
                />
            </BulkActionsProvider>
        </Segment>
    );
};

export default SubscriptionProducts;
