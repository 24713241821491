import React, { useContext } from 'react';
import { Icon, Table as SemanticTable } from 'semantic-ui-react';
import { DateTimeUtils, Table } from 'dyl-components';

import { QuoteBuilderContext } from 'shared/context/QuoteBuilderProvider';
import { StringUtils } from 'utils';
import QuoteID from 'shared/QuoteID';

const PAYMENT_NETWORK_ICONS = {
    'AmericanExpress': 'fab fa-cc-amex',
    'Mastercard': 'fas fa-cc-mastercard',
    'Discover': 'fas fa-cc-discover',
    'Visa': 'fas fa-cc-visa',
    'DinersClub': 'fas fa-cc-diners-club',
    'JCB': 'fas fa-cc-jcb',
}

const PaymentInfo = ({ info }) => {
    const { payment_type: type, payment_account } = info;
    if (type === "eCheck") {
        return (
            <div>
                <Icon className={`fas fa-money-check-dollar`} /> {payment_account.replace("XXXX", "****")}
            </div>
        )
    }
    if (type === "credit_card") {
        return (
            <div>
                <Icon className={PAYMENT_NETWORK_ICONS[info.payment_network]} /> {payment_account.replace("XXXX", "****")}
            </div>
        )
    }
    return null;
}

const Subrows = ({ invoices }) => {
    const { onViewInvoice } = useContext(QuoteBuilderContext);

    return (
        <Table.CollapsibleRowContent>
            <Table.Cell width={1} />
            <Table.Cell colSpan={7}>
                <div className="OrdersTable__OrderInvoicesTable">
                    <Table striped={false}>
                        <SemanticTable.Header className='Table__header--primary'>
                            <SemanticTable.Row>
                                <SemanticTable.HeaderCell>
                                    Invoice Date
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Due On
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell className="OrderInvoicesTable__InvoiceIDColumn">
                                    Invoice ID
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Billed Amount
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Payment Method
                                </SemanticTable.HeaderCell>
                                <SemanticTable.HeaderCell>
                                    Invoice Status
                                </SemanticTable.HeaderCell>
                            </SemanticTable.Row>
                        </SemanticTable.Header>
                        <Table.Body>
                            {invoices.map(invoice => (
                                <Table.Row>
                                    <SemanticTable.Cell>
                                        <div>
                                            <b>
                                                {DateTimeUtils.formatEpoch(
                                                    invoice.created,
                                                    DateTimeUtils.WORD_DATE_FORMAT
                                                )}
                                            </b>
                                        </div>
                                    </SemanticTable.Cell>
                                    <SemanticTable.Cell>
                                        {invoice.due_on > 0 && (<div>
                                            <b>
                                                {DateTimeUtils.formatEpoch(
                                                    invoice.due_on,
                                                    DateTimeUtils.WORD_DATE_FORMAT
                                                )}
                                            </b>
                                        </div>)}
                                    </SemanticTable.Cell>
                                    <SemanticTable.Cell>
                                        <QuoteID 
                                            hasPdf
                                            id={invoice.id}
                                            onEditQuote={() => {
                                                onViewInvoice(invoice.id)
                                            }}
                                            onViewProposal={() => {
                                                onViewInvoice(invoice.id)
                                            }}
                                        />
                                    </SemanticTable.Cell>
                                    <SemanticTable.Cell>
                                        {invoice.amount &&
                                            `$${StringUtils.formatDecimal(`${invoice.amount}`, true)}`}
                                    </SemanticTable.Cell>
                                    <SemanticTable.Cell>
                                        <PaymentInfo info={invoice.payment_data} />
                                    </SemanticTable.Cell>
                                    <SemanticTable.Cell>
                                        {StringUtils.capitalize(invoice.invoice_status)}
                                    </SemanticTable.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </Table.Cell>
        </Table.CollapsibleRowContent>
    );
}

export default Subrows;
