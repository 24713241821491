import React from 'react';
import { Segment, Dimmer, Loader, Header } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { ManageSettings } from 'dyl-components';
import rolesActions from 'actions/roles';
import { Notification, STATUS_TYPES } from 'dyl-components';
import permissionsActions from 'actions/permissions';
import PermissionRolesForm from './subcomponents/PermissionRoleForm';
import { Navigate } from 'react-router';
import { withRouter } from 'shared/withRouter';
class AddEditPermissionSettings extends React.Component {
    state = {
        name: '',
        description: '',
        permissionRoles: [],
        isLoading: true,
        isLoadingAllPermissions: true,
        isLoadingConfigs: true,
        currentRoleConfigs: [],
        type: 'add',
        id: null,
        anySelected: {},
        hasChecksSelected: false,

        redirectToAdd: false,
        redirectToView: false
    }
    setCheckedItems() {
        const { currentRoleConfigs, permissionRoles, type } = this.state;
        let anySelected = {};

        const update = permissionRoles.map((permissions, idx) => {

            permissions.permissionSets.map((items) => {
                items.permissions.map(item => {
                    if (type === 'view') {
                        item.disabled = true;
                    }
                    if (currentRoleConfigs[item.type]) {
                        item.checked = true
                        anySelected[idx] = true
                    }
                    return item;
                })
                return items;
            })
            return permissions;

        });
        this.setState({
            permissionRoles: update,
            anySelected,
            isLoadingConfigs: false
        });
    }

    getType = (pathInfo) => {
        let checkedView = 'view';

        if (pathInfo.includes('edit_permission')) { checkedView = 'edit' }
        else if (pathInfo.includes('add_permission')) { checkedView = 'add' }

        return checkedView;
    }

    checkRoles = (roleId) => {

        if (roleId) {
            const { name = '', description = '' } = this.props.location.state?.incomingPermission?.permission;

            this.setState({ name, description })

            if (!name) {
                //if they pass an unknow roleId send to create new roles page
                this.setState({ redirectToAdd: true });
            } else {
                this.props.onReadRole(roleId)
                    .then((resp) => {
                        this.setState({
                            currentRoleConfigs: resp.permissions
                        }, this.setCheckedItems());

                    }).catch(e => {
                        Notification.alert('There was a error retreving the settings', STATUS_TYPES.ERROR, true);
                        this.setState({ redirectToAdd: true });
                    });
            }
        } else {
            this.setState({
                isLoadingConfigs: false
            });
        }
    }

    getPermissionData() {
        //load all permissions
        this.props.onReadPermissions()
            .then((response) => {
                if (this.props.location.state?.incomingPermission) {
                    const { type } = this.props.location?.state?.incomingPermission || 'add';
                    const { name, description, id } = this.props.location.state?.incomingPermission?.permission || '';

                    this.setState({ name, description, id, type })
                }

                this.setState({
                    permissionRoles: response,
                    isLoadingAllPermissions: false,
                })
            }).then(() => {

                let tempID = this.props.params.roleId || this.state.id || null;
                if (tempID) {
                    this.checkRoles(tempID);
                }
                else {
                    this.setState({ isLoadingConfigs: false })
                }
            }).catch(error => {
                const errorMessage = error.message

                Notification.alert(errorMessage, STATUS_TYPES.ERROR, true);
            });
    }

    componentDidMount() {
        this.getPermissionData();
        this.setCheckedItems();  
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentRoleConfigs !== this.state.currentRoleConfigs) {
            this.setState({ isLoadingConfigs: true })
            this.setCheckedItems();
        }
    }

    checkUpdateButton = (checks = null, value = null) => {
        const { permissionRoles } = this.state;
        const hasChecksSelected = (JSON.stringify(permissionRoles).includes('"checked":true')),
            finalButtonCheck = (hasChecksSelected)


        this.setState({ hasChecksSelected: finalButtonCheck });
    }

    getRoles = () => {

        const { permissionRoles } = this.state || ['test'];
        const testRoles = [];
        permissionRoles.forEach((roles) => {
            if (roles.permissionSets) {
                roles.permissionSets.map((role) => {
                    if (role.permissions) {
                        const { permissions } = role;
                        permissions.filter((item) => {
                            if (item.checked) {
                                testRoles.push({name: item.type, scope: "global"})
                            }
                            return false;
                        })
                    }
                    return false;
                })
            }
            return false;
        });
        return testRoles;
    }

    onSave = ({ name, description }, setError) => {

        const { type, id } = this.state;
        const setRoles = this.getRoles();
        const newRole = {
            name: name.trim(),
            description: description.trim(),
            permissions: setRoles
        };

        if (type === 'edit') {
            this.props.onUpdate(id, newRole)
                .then(resp => {
                    this.setState({
                        name: '',
                        description: '',
                        permissionRoles: [],
                        isLoading: true,
                        currentRoleConfigs: {},
                        type: '',
                        id: null
                    });
                    this.setState({ redirectToView: true });
                }).catch(error => {
                    const errorMessage = (error.message === 'Duplicate' || error.response.data.message === 'Duplicate') ? "Permission Profile Role Name already exists" : 'Failed to create Role';
                    this.setState({ errorMessage });

                    //TODO: gotta work on getting standardized error statuses from the BE APIS e.g. for the AddTeams component the BE returns a status code of 409.  this response just returns a msg saying 'Duplicate'
                    if ((error.message === 'Duplicate' || error.response.data.message === 'Duplicate') && setError) {
                        setError("name", { type: "unique", message: errorMessage });
                    }

                    Notification.alert(errorMessage, STATUS_TYPES.ERROR, true);
                });
        } else {
            this.props.onSave(newRole)
                .then(resp => {
                    this.setState({
                        name: '',
                        description: '',
                        redirectToView: true
                    });
                }).catch(error => {
                    const errorMessage = (error.message === 'Duplicate') ? "Permission Profile Role Name already exists" : 'Failed to create Role';
                    this.setState({ errorMessage });
                    if (error.message === 'Duplicate' && setError) {
                        setError("name", { type: "unique", message: errorMessage });
                    }

                    Notification.alert(errorMessage, STATUS_TYPES.ERROR, true);
                });
        }
    }

    render() {

        const { name, description, anySelected, isLoadingConfigs, hasChecksSelected } = this.state;
        const { type } = this.state || 'add';
        const updatedName = (type === 'duplicate') ? `Duplicate of ${name}` : name;
        const header = (type === 'edit') ? `Edit '${name}'` : null;
        // if(isLoadingConfigs) {this.setCheckedItems()}

        if (this.state.redirectToAdd) {
            return <Navigate to="/settings/add-permission-profile" />
        }

        if (this.state.redirectToView) {
            return <Navigate to="/settings/permission-profile" />
        }

        return (
            <div>
                {this.state.isLoadingAllPermissions ?
                    <Dimmer active>
                        <Loader />
                    </Dimmer>
                    :
                    <React.Fragment>
                        { header && <Header as='h3'>Edit "{updatedName}"</Header>}
                        <PermissionRolesForm
                            onSave={this.onSave}
                            permissionConfigs={isLoadingConfigs ?
                                <Segment>
                                    <Dimmer active>
                                        <Loader active />
                                    </Dimmer>
                                </Segment>
                                :
                                <ManageSettings allSelected={anySelected} permissionRoles={this.state.permissionRoles} disabled={type === 'view'} checkUpdateButton={this.checkUpdateButton} />
                            }
                            name={updatedName}
                            description={description}
                            header={header}
                            type={type}
                            checkUpdateButton={this.checkUpdateButton}
                            hasChecksSelected={hasChecksSelected}
                        />
                </React.Fragment>
                }
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    onSave: (newRole) => {
        return dispatch(rolesActions.createRole(newRole));
    },
    onUpdate: (id, updatedRole) => {
        return dispatch(rolesActions.updateRole(id, updatedRole))
    },
    onReadRole: (roleId) => {
        return dispatch(rolesActions.readRole(roleId));
    },
    onReadPermissions: () => {
        return dispatch(permissionsActions.readPermissions());
    }
});

const mapStateToProps = state => ({
    isCreatingRole: state.roles.isCreatingRole,
    permissionRoles: state.permissions.appPermissions,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddEditPermissionSettings));
